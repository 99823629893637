import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import collapse from "@alpinejs/collapse";

import { DateTime } from "luxon";

import graphQlClient from './graphQlClient';
import graphQlQueries from './graphQlQueries';

import AOS from "aos";

declare global {
    interface Window {
        Alpine: typeof Alpine;
    }
}

window.Alpine = Alpine;

// @ts-ignore
Alpine.plugin(focus);
// @ts-ignore
Alpine.plugin(intersect);
// @ts-ignore
Alpine.plugin(collapse);


/**
 * Navigation sticky
 */
Alpine.data("nav", (props: { mobileOpen: boolean, activeMenu: number, lightMode: string }) => ({
  mobileOpen: <boolean>props.mobileOpen,
  activeMenu: <number|null>props.activeMenu,
  lightMode: <string>props.lightMode,
  lastScrollPosition: window.scrollY,
  isSticky: false,
  isScrollingUp: false,
  handleScroll() {
    const currentScrollPosition = window.scrollY

    if (currentScrollPosition <= 0) {
      this.isSticky = false
      this.isScrollingUp = false
      this.lastScrollPosition = 0
    } else {
      this.isScrollingUp = currentScrollPosition < this.lastScrollPosition && currentScrollPosition > 0

      this.lastScrollPosition = currentScrollPosition
    }
  },
  handleScrollEnd() {
    if (window.scrollY <= 0) {
      this.isSticky = false;
      this.isScrollingUp = false
      this.lastScrollPosition = 0
    }
  },
  init() {
    this.$watch('isSticky', () => {
      if (this.isSticky) {
        // Set the background based on the initial color mode state
        if (this.lightMode == '1') {
          this.$root.classList.value = 'fixed top-0 z-20 flex flex-col w-full overflow-y-auto max-h-dvh bg-white-50 -translate-y-full lg:overflow-visible'
        } else {
          this.$root.classList.value = 'fixed top-0 z-20 flex flex-col w-full overflow-y-auto max-h-dvh bg-cloud-500 -translate-y-full lg:overflow-visible'
        }
      } else {
        this.$root.classList.value = 'absolute z-20 flex flex-col w-full overflow-y-auto max-h-dvh lg:overflow-visible';
      }
    })

    this.$watch('isScrollingUp', () => {
      // Only manipulate if the element is "sticky" (i.e. fixed)
      if (this.isSticky) {
        if (!this.$root.classList.contains('transition-transform')) {
          this.$root.classList.add('transition-transform', 'duration-700')
        }

        if (this.isScrollingUp) {
          this.$root.classList.remove('-translate-y-full')
        } else {
          this.$root.classList.add('-translate-y-full')
        }
      }
    })
  }
}))

/**
 * Handle external links
 */
Alpine.data("externalLinks", (props: { openNotice: boolean }) => ({
  openNotice: props.openNotice,
  newWindow: false,
  externalSite: <string|null>null,
  handleContinue() {
    if (this.externalSite) {

      if (this.newWindow) {
        window.open(this.externalSite, '_blank')
      } else {
        window.location.href = this.externalSite
      }

      this.openNotice = false
    }
  },
  getDomain(hostname: string) {
    const parts = hostname.split('.').slice(-2) // Get the last two parts of the hostname
    return parts.join('.')
  },
  init() {
    document.addEventListener("click", (event) => {
      const target = <HTMLElement>event.target
      if (target) {
        const anchor = target.closest('a')

        // If no anchor
        if (!anchor) {
          return
        }

        const hostDomain = this.getDomain(window.location.hostname)
        const targetDomain = this.getDomain(anchor.hostname)

        // If a mailto link or # marker then skip
        if (anchor.href.startsWith('mailto') || anchor.href.startsWith('#')) {
          return
        }

        // If a relative link then skip
        if (!anchor.href.startsWith('http')) {
          return
        }

        // Handle everything else by checking that the root domains match so subdomains are alright
        if (targetDomain !== hostDomain) {
          this.externalSite = anchor.href
          this.openNotice = true

          if (anchor.target === '_blank') {
            this.newWindow = true
          }

          event.preventDefault()
        }
      }
    })
  }
}))

/**
 * Accordion handler
 */
Alpine.data("accordion", (props: { active: null|string }) => ({
  active: props.active,
  toggle(value: string) {
    this.active = this.active == value ? null : value
  },
  isActive(value: string) {
    return this.active == value
  }
}))

interface BatchGraphQLRequest {
  query: string,
  variables: object
}

interface EventListQueryVariables {
  id?: string[]
  eventStart?: string[]
  limit: number|null
  offset: number
}

interface FilingListQueryVariables {
  id?: string[]
  dateFiled?: string[]
  form?: string[],
  limit: number|null,
  offset: number
}

interface PressReleaseListQueryVariables {
  id?: string[]
  datePublished?: string[]
  limit: number|null
  offset: number
}

interface PublicationListQueryVariables {
  id?: string[]
  postDate?: string[]
  publicationDrug?: string[],
  limit: number|null,
  offset: number
}

/**
 * Create a "magic" for listing utilities
 */
Alpine.magic('listingUtils', () => {
  return {
    getDateVariable(date: string) {
      let year = Number(date)
      return ["and", ">=" + year + "-01-01", "<" + ++year + "-01-01"]
    },
    makeUppercase(string: string) {
      return string.toUpperCase()
    },
  }
})


/**
 * Events Listing GraphQL
 */
Alpine.data('eventList', (props: { limit: number, offset: number, totalPages: number, fields: { eventStart: string[] } }) => ({
  perPage: props.limit,
  offset: props.offset,
  totalPages: props.totalPages,
  fields: props.fields,
  entries: <any[]> [],
  id: <string[]|null> null,
  currentPage: <number> 1,
  filtered: <boolean> false,
  loading: <boolean> false,
  getQueryVariables(): EventListQueryVariables {
    const variables: EventListQueryVariables = {
      limit: props.limit,
      offset: this.offset
    }

    if (this.id) {
      variables.id = this.id
    }

    return variables
  },
  setTotalPages(entryCount: number) {
    this.totalPages = Math.ceil(entryCount / this.perPage)
  },
  // Create batch graphQl requests
  createBatch() {

    const batch: BatchGraphQLRequest[] = []

    this.fields.eventStart.forEach( (date) => {

      const variables = this.getQueryVariables()
      // Set the year we are looking for
      variables.eventStart = this.$listingUtils.getDateVariable(date)
      // For batch queries we need the total results so no offsets or limiting
      variables.limit = null
      variables.offset = 0

      batch.push({ query: graphQlQueries.eventIds, variables: variables})
    })

    return batch;
  },
  setFilters() {

    this.filtered = true;
    this.loading = true;

    /* Reset variables when the filters change */
    this.id = null;
    this.entries = [];
    this.offset = 0;
    this.currentPage = 1;

    /* If year field length is more than 1 we query and merge IDs to get a result set */
    if (this.fields.eventStart.length > 1) {

      const batchQuery = this.createBatch();

      graphQlClient.batchFetchRequest(batchQuery)
        .then( (data) => {
          // Combine the results from all queries
          const merged = data.reduce((acc: any[], data: any) => [...acc, ...data.entries], [])
          // Sort by date
          merged.sort((a, b) => {
            const dateA: any = DateTime.fromISO(a.eventStart)
            const dateB: any = DateTime.fromISO(b.eventStart)
            return dateB - dateA
          })
          // Pluck the IDs
          this.id = merged.map(entry => entry['id'])

          this.setTotalPages(this.id.length)

          const variables = this.getQueryVariables()
          graphQlClient.fetchRequest(graphQlQueries.events, variables)
            .then((data: any) => {
              this.entries = data.entries
              this.loading = false;
            })
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables()

      if (this.fields.eventStart[0]) {
        variables.eventStart = this.$listingUtils.getDateVariable(this.fields.eventStart[0])
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.events, variables)
        .then( (data: any) => {
          this.setTotalPages(data.entryCount)
          this.entries = data.entries
          this.loading = false
        })
    }
  },
  showMore() {
    this.offset = this.currentPage * this.perPage

    if (this.id) {
      const variables = this.getQueryVariables()
      graphQlClient.fetchRequest(graphQlQueries.events, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables()

      if (this.fields.eventStart[0]) {
        variables.eventStart = this.$listingUtils.getDateVariable(this.fields.eventStart[0])
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.events, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
      })
    }

    this.currentPage++
  },
  getShortDate(entry: any) {
    const endDate = DateTime.fromISO(entry.endDate)
    const startDate = DateTime.fromISO(entry.eventStart)

    const endDateOnly = endDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})
    const startDateOnly = startDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

    if (endDate.isValid && endDateOnly > startDateOnly) {
      return startDate.toFormat('MMM dd') + ' - ' + endDate.toFormat('MMM dd y')
    } else if (endDate.isValid) {
      return startDate.toFormat('MMM dd y') + "<br>" + startDate.toFormat('h:mm a') + ' - ' + endDate.toFormat('h:mm a ZZZZ')
    } else {
      return startDate.toFormat('MMM dd y') + "<br>" + startDate.toFormat('h:mm a ZZZZ')
    }
  },
  getLongDate(entry: any) {
    const endDate = DateTime.fromISO(entry.eventEnd)
    const startDate = DateTime.fromISO(entry.eventStart)

    const endDateOnly = endDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})
    const startDateOnly = startDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

    if (endDate.isValid && endDateOnly > startDateOnly) {
      return startDate.toFormat('MMM dd') + ' - ' + endDate.toFormat('MMM dd y')
    } else if (endDate.isValid) {
      return startDate.toFormat('MMM dd y, h:mm a') + " - " + endDate.toFormat('h:mm a ZZZZ')
    } else {
      return startDate.toFormat('MMM dd y, h:mm a ZZZZ')
    }
  },
  isUpcoming(isoStart: any) {
    return DateTime.fromISO(isoStart) > DateTime.now()
  }
}))


/**
 * Filing List
 */
Alpine.data('filingList', (props: { limit: number, offset: number, totalPages: number, fields: { dateFiled: string[], form: string[] } }) => ({
  perPage: props.limit,
  offset: props.offset,
  totalPages: props.totalPages,
  fields: props.fields,
  entries: <any[]> [],
  id: <string[]|null> null,
  currentPage: <number> 1,
  filtered: <boolean> false,
  loading: <boolean> false,
  getQueryVariables(): FilingListQueryVariables {
    const variables: FilingListQueryVariables = {
      limit: props.limit,
      offset: this.offset
    }

    if (this.id) {
      variables.id = this.id
    }

    return variables
  },
  setTotalPages(entryCount: number) {
    this.totalPages = Math.ceil(entryCount / this.perPage)
  },
  // Create batch graphQl requests
  createBatch() {

    const batch: BatchGraphQLRequest[] = []

    this.fields.dateFiled.forEach( (date) => {

      const variables = this.getQueryVariables()
      // Set the year we are looking for (no limit or offset is allowed on the query in graphQl
      variables.dateFiled = this.$listingUtils.getDateVariable(date)

      if (this.fields.form.length > 0) {
        variables.form = this.fields.form
      }

      batch.push({ query: graphQlQueries.filingIds, variables: variables})
    })

    return batch;
  },
  setFilters() {
    this.filtered = true;
    this.loading = true;

    /* Reset variables when the filters change */
    this.id = null;
    this.entries = [];
    this.offset = 0;
    this.currentPage = 1;

    /* If year field length is more than 1 we query and merge IDs to get a result set */
    if (this.fields.dateFiled.length > 1) {

      const batchQuery = this.createBatch();

      graphQlClient.batchFetchRequest(batchQuery)
        .then( (data) => {
          // Combine the results from all queries
          const merged = data.reduce((acc: any[], data: any) => [...acc, ...data.entries], [])
          // Sort by date
          merged.sort((a, b) => b.dateFiled - a.dateFiled)
          // Pluck the IDs
          this.id = merged.map(entry => entry['id'])

          this.setTotalPages(this.id.length)

          const variables = this.getQueryVariables()
          graphQlClient.fetchRequest(graphQlQueries.filings, variables)
            .then((data: any) => {
              this.entries = data.entries
              this.loading = false
            })
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables();
      if (this.fields.dateFiled[0]) {
        variables.dateFiled = this.$listingUtils.getDateVariable(this.fields.dateFiled[0])
      }

      if (this.fields.form.length > 0) {
        variables.form = this.fields.form
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.filings, variables)
        .then((data: any) => {
          this.setTotalPages(data.entryCount)
          this.entries = data.entries
          this.loading = false
        })
    }
  },
  showMore() {
    this.offset = this.currentPage * this.perPage

    if (this.id) {
      const variables = this.getQueryVariables()
      graphQlClient.fetchRequest(graphQlQueries.filings, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables()

      if (this.fields.dateFiled[0]) {
        variables.dateFiled = this.$listingUtils.getDateVariable(this.fields.dateFiled[0])
      }

      if (this.fields.form.length > 0) {
        variables.form = this.fields.form
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.filings, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
        })
    }

    this.currentPage++
  }
}))


/**
 * Press Release List
 */
Alpine.data('pressReleaseList', (props: { limit: number, offset: number, totalPages: number, fields: { datePublished: string[] } }) => ({
  perPage: props.limit,
  offset: props.offset,
  totalPages: props.totalPages,
  fields: props.fields,
  entries: <any[]> [],
  id: <string[]|null> null,
  currentPage: <number> 1,
  filtered: <boolean> false,
  loading: <boolean> false,
  getQueryVariables(): PressReleaseListQueryVariables {
    const variables: PressReleaseListQueryVariables = {
      limit: props.limit,
      offset: this.offset
    }

    if (this.id) {
      variables.id = this.id
    }

    return variables
  },
  setTotalPages(entryCount: number) {
    this.totalPages = Math.ceil(entryCount / this.perPage)
  },
  // Create batch graphQl requests
  createBatch() {

    const batch: BatchGraphQLRequest[] = []

    this.fields.datePublished.forEach( (date) => {

      const variables = this.getQueryVariables()
      // Set the year we are looking for
      variables.datePublished = this.$listingUtils.getDateVariable(date)

      batch.push({ query: graphQlQueries.pressReleaseIds, variables: variables})
    })

    return batch;
  },
  setFilters() {
    this.filtered = true;
    this.loading = true;

    /* Reset variables when the filters change */
    this.id = null;
    this.entries = [];
    this.offset = 0;
    this.currentPage = 1;

    /* If year field length is more than 1 we query and merge IDs to get a result set */
    if (this.fields.datePublished.length > 1) {

      const batchQuery = this.createBatch();

      graphQlClient.batchFetchRequest(batchQuery)
        .then( (data) => {
          // Combine the results from all queries
          const merged = data.reduce((acc: any[], data: any) => [...acc, ...data.entries], [])
          // Sort by date
          merged.sort((a, b) => b.datePublished - a.datePublished)
          // Pluck the IDs
          this.id = merged.map(entry => entry['id'])

          this.setTotalPages(this.id.length)

          const variables = this.getQueryVariables()
          graphQlClient.fetchRequest(graphQlQueries.pressReleases, variables)
            .then((data: any) => {
              this.entries = data.entries
              this.loading = false
            })
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables();
      if (this.fields.datePublished[0]) {
        variables.datePublished = this.$listingUtils.getDateVariable(this.fields.datePublished[0])
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.pressReleases, variables)
        .then((data: any) => {
          this.setTotalPages(data.entryCount)
          this.entries = data.entries
          this.loading = false
        })
    }
  },
  showMore() {
    this.offset = this.currentPage * this.perPage

    if (this.id) {
      const variables = this.getQueryVariables()
      graphQlClient.fetchRequest(graphQlQueries.pressReleases, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables()

      if (this.fields.datePublished[0]) {
        variables.datePublished = this.$listingUtils.getDateVariable(this.fields.datePublished[0])
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.pressReleases, variables)
        .then((data: any) => {
          this.entries = this.entries.concat(data.entries)
        })
    }

    this.currentPage++
  }
}))


/**
 * Filter publications list
 */
Alpine.data('publicationsList', (props: { limit: number, offset: number, totalPages: number, fields: { postDate: string[], publicationDrug: [] } }) => ({
  perPage: props.limit,
  offset: props.offset,
  totalPages: props.totalPages,
  fields: props.fields,
  entries: <any[]> [],
  id: <string[]|null> null,
  currentPage: <number> 1,
  filtered: <boolean> false,
  loading: <boolean> false,
  getQueryVariables(): PublicationListQueryVariables {
    const variables: PublicationListQueryVariables = {
      limit: props.limit,
      offset: this.offset
    }

    if (this.id) {
      variables.id = this.id
    }

    return variables
  },
  setTotalPages(entryCount: number) {
    this.totalPages = Math.ceil(entryCount / this.perPage)
  },
  // Create batch graphQl requests
  createBatch() {

    const batch: BatchGraphQLRequest[] = []

    this.fields.postDate.forEach( (date) => {

      const variables = this.getQueryVariables()
      // Set the year we are looking for
      variables.postDate = this.$listingUtils.getDateVariable(date)

      if (this.fields.publicationDrug.length > 0) {
        variables.publicationDrug = this.fields.publicationDrug
      }

      batch.push({ query: graphQlQueries.publicationIds, variables: variables})
    })

    return batch;
  },
  setFilters() {

    this.filtered = true;
    this.loading = true;

    /* Reset variables when the filters change */
    this.id = null;
    this.entries = [];
    this.offset = 0;
    this.currentPage = 1;

    /* If year field length is more than 1 we query and merge IDs to get a result set */
    if (this.fields.postDate.length > 1) {

      const batchQuery = this.createBatch();

      graphQlClient.batchFetchRequest(batchQuery)
        .then((data) => {
          // Combine the results from all queries
          const merged = data.reduce((acc: any[], data: any) => [...acc, ...data.entries], [])
          // Sort by date
          merged.sort((a, b) => b.postDate - a.postDate)
          // Pluck the IDs
          this.id = merged.map(entry => entry['id'])

          this.setTotalPages(this.id.length)

          const variables = this.getQueryVariables()

          graphQlClient.fetchRequest(graphQlQueries.publications, variables)
            .then((data: any) => {
              this.entries = data.entries
              this.loading = false;
            })
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables();

      if (this.fields.postDate[0]) {
        variables.postDate = this.$listingUtils.getDateVariable(this.fields.postDate[0])
      }

      if (this.fields.publicationDrug.length > 0) {
        variables.publicationDrug = this.fields.publicationDrug
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.publications, variables)
        .then((data: any) => {
          this.setTotalPages(data.entryCount)
          this.entries = data.entries
          this.loading = false;
        })
    }
  }
}))


/**
 * Quarterlies list
 */
Alpine.data('quarterliesList', (props: { fields: { dateFiled: string[] } }) => ({
  fields: props.fields,
  entries: <any[]> [],
  id: <string[]|null> null,
  filtered: <boolean> false,
  loading: <boolean> false,
  getQueryVariables(): FilingListQueryVariables {
    const variables: FilingListQueryVariables = {
      form: ['10-Q'],
      limit: null,
      offset: 0
    }

    if (this.id) {
      variables.id = this.id
    }

    return variables
  },
  // Create batch graphQl requests
  createBatch() {

    const batch: BatchGraphQLRequest[] = []

    this.fields.dateFiled.forEach( (date) => {

      const variables = this.getQueryVariables()
      // Set the year we are looking for (no limit or offset is allowed on the query in graphQl
      variables.dateFiled = this.$listingUtils.getDateVariable(date)

      batch.push({ query: graphQlQueries.filingIds, variables: variables})
    })

    return batch;
  },
  setFilters() {
    this.filtered = true;
    this.loading = true;

    /* Reset variables when the filters change */
    this.id = null;
    this.entries = [];

    /* If year field length is more than 1 we query and merge IDs to get a result set */
    if (this.fields.dateFiled.length > 1) {

      const batchQuery = this.createBatch();

      graphQlClient.batchFetchRequest(batchQuery)
        .then( (data) => {
          // Combine the results from all queries
          const merged = data.reduce((acc: any[], data: any) => [...acc, ...data.entries], [])
          // Sort by date
          merged.sort((a, b) => b.dateFiled - a.dateFiled)
          // Pluck the IDs
          this.id = merged.map(entry => entry['id'])

          const variables = this.getQueryVariables()
          graphQlClient.fetchRequest(graphQlQueries.filings, variables)
            .then((data: any) => {
              this.entries = data.entries
              this.loading = false
            })
        })
    } else {
      // Set the variables
      const variables = this.getQueryVariables();
      if (this.fields.dateFiled[0]) {
        variables.dateFiled = this.$listingUtils.getDateVariable(this.fields.dateFiled[0])
      }

      // Run a single query
      graphQlClient.fetchRequest(graphQlQueries.filings, variables)
        .then((data: any) => {
          this.entries = data.entries
          this.loading = false
        })
    }
  }
}))


/**
 * Email Updates
 */
interface EmailAlertFields {
  contents: [],
  email: string
}

interface EmailAlertValidation {
  contents: { status: boolean, checked: boolean },
  email: { status: boolean, checked: boolean }
}

Alpine.data('emailAlerts', (props: EmailAlertFields) => ({
  contents: props.contents,
  email: props.email,
  action: '',
  isValid: false,
  isSending: false,
  success: <boolean|null> null,
  errors: [],
  validation: <EmailAlertValidation> {
    contents: { status: false, checked: false },
    email: { status: false, checked: false }
  },
  init() {
    this.action = (this.$root.querySelector("input[name='action']") as HTMLInputElement).value

    this.$watch('email', () => {
      this.validateEmail()
      this.success = null
      this.errors = []
    })

    this.$watch('contents', () => {
      this.validateContents()
      this.success = null
      this.errors = []
    })

    this.$watch('validation', (value: EmailAlertValidation) => {
      this.isValid = (value.email.status && value.contents.status)
    })
  },
  validateEmail() {
    if (this.email == '') {
      this.validation.email.status = false
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.validation.email.status = emailRegex.test(this.email)
    }
    this.validation.email.checked = true
  },
  validateContents() {
    this.validation.contents.status = this.contents.length > 0
    this.validation.contents.checked = true
  },
  setAlert() {

    if (!this.isValid) {
      // Set these in case the user just clicks submit without triggering a change
      this.validateEmail()
      this.validateContents()
    }

    if (this.isValid) {
      const form = this.$root.querySelector('form')

      if (form) {

        this.isSending = true

        const formData = new FormData(form)
        formData.append('email', this.email)

        this.contents.forEach( (content) => {
          formData.append('contents[]', content)
        })

        const csrfToken = (form.querySelector("input[name='CRAFT_CSRF_TOKEN']") as HTMLInputElement).value

        fetch(this.action, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
          .then(response => response.json())
          .then(result => {
            this.success = result.success.length > 0
            this.errors = result.errors

            this.isSending = false
          });
      }
    }
  }
}))


/**
 * Contact Form
 */
interface ContactFields {
  name: string,
  email: string,
  subject: string,
  message: string
}

interface ContactValidation {
  name: { status: boolean, checked: boolean },
  email: { status: boolean, checked: boolean },
  subject: { status: boolean, checked: boolean },
  message: { status: boolean, checked: boolean }
}

Alpine.data('contactForm', (props: { fields: ContactFields }) => ({
  fields: props.fields,
  action: '',
  isValid: false,
  isSending: false,
  success: <boolean|null> null,
  validation: <ContactValidation> {
    name: { status: false, checked: false },
    email: { status: false, checked: false },
    subject: { status: false, checked: false },
    message: { status: false, checked: false }
  },
  init() {
    this.action = (this.$root.querySelector("input[name='action']") as HTMLInputElement).value

    this.$watch('fields.email', () => {
      this.validateEmail()
      this.success = null
    })

    this.$watch('fields.name', () => {
      this.validateField('name')
      this.success = null
    })

    this.$watch('fields.subject', () => {
      this.validateField('subject')
      this.success = null
    })

    this.$watch('fields.message', () => {
      this.validateField('message')
      this.success = null
    })

    this.$watch('validation', (value: ContactValidation) => {
      if (value.name.status && value.email.status && value.subject.status && value.message.status) {
        this.isValid = true
      }
    })
  },
  validateEmail() {
    if (this.fields.email == '') {
      this.validation.email.status = false
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.validation.email.status = emailRegex.test(this.fields.email)
    }
    this.validation.email.checked = true
  },
  validateField(key: keyof ContactValidation) {
    this.validation[key].status = (this.fields[key] != '')
    this.validation[key].checked = true
  },
  submitForm() {

    // Validate fields
    this.validateEmail()
    this.validateField('name')
    this.validateField('subject')
    this.validateField('message')

    if (this.isValid) {
      const form = this.$root.querySelector('form');

      if (form) {
        const formData = new FormData(form);
        formData.append('fromName', this.fields.name)
        formData.append('fromEmail', this.fields.email)
        formData.append('subject', this.fields.subject)
        formData.append('message', this.fields.message)

        this.isSending = true;

        const csrfToken = (form.querySelector("input[name='CRAFT_CSRF_TOKEN']") as HTMLInputElement).value

        fetch(this.action, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': csrfToken,
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
          .then(response => response.json())
          .then(result => {
            if (result.errors && result.errors.length > 0) {
              this.success = false

              result.errors.forEach( (message: any, key: any) => {
                if (key == 'fromEmail') {
                  this.validation.email.status = false
                } else if (key == 'fromName') {
                  this.validation.name.status = false
                } else {
                  // @ts-ignore
                  this.validation[key].status = false
                }
              })
            } else {
              this.success = true
            }

            this.isSending = false
          });
      }
    }
  }
}))


/**
 * Initialize custom items on DOM loaded
 */
document.addEventListener("DOMContentLoaded", async () => {

  const importAlpinePromises = [];

  /**
   * Lottie Animation player
   */
  const lottie = document.querySelector('[x-data^="lottieAnimation"]')
  if (lottie) {
    importAlpinePromises.push(
      import('./lottie').then(module => {
        Alpine.data('lottieAnimation', module.default)
      })
    )
  }

  /**
   * 3D Molecule Viewer
   */
  const molecule = document.querySelector('[x-data^="moleculeViewer"]')
  if (molecule) {
    importAlpinePromises.push(
      import('./molecule').then(module => {
        Alpine.data('moleculeViewer', module.default)
      })
    )
  }

  /**
   * Stock chart
   */
  const stockChart = document.querySelector('[x-data^="stockChart"]');
  if (stockChart) {
    importAlpinePromises.push(
      import('./stockChart').then(module => {
        Alpine.data('stockChart', module.default)
      })
    )
  }

  /**
   * Historic Pricing
   */
  const historic = document.querySelector('[x-data^="historicalPrice"]')
  if (historic) {
    importAlpinePromises.push(
      import('./historic').then(module => {
        Alpine.data('historicalPrice', module.default)
      })
    )
  }

  await Promise.all(importAlpinePromises)

  Alpine.start()

})


/**
 * Initialize AOS.js for animating elements into view
 */
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 200, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 550, // values from 0 to 3000, with step 50ms
    easing: "ease-in", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding window
});
