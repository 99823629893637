interface GraphQLResponse<T> {
  data?: T;
  errors?: Array<{ message: string }>;
}

interface GraphQLRequestVariables {
  [key: string]: any;
}

interface GraphQLRequestOptions {
  method: "POST",
  headers: { [key:string]: string },
  body: string
}

interface BatchGraphQLRequest {
  query: string;
  variables?: GraphQLRequestVariables;
}

const graphQlClient = {

  url: window.location.origin + "/api",

  /**
   * Send a GraphQl request
   * @param {string} query
   * @param {object} variables
   * @returns {Promise<any>}
   */
  async fetchRequest<T>(query: string, variables: object): Promise<T> {

    const request: GraphQLRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ query, variables })
    }

    try {
      const response = await fetch(this.url, request)
      const result: GraphQLResponse<T> = await response.json()

      if (response.ok && !result.errors) {
        return result.data!
      } else {
        // Handle and throw GraphQL errors as a proper error
        const error = result.errors ? result.errors : new Error('Network response was not ok.');
        throw error;
      }
    } catch (error: any) {
      // Handle network errors
      console.error("Error making the request: ", error);
      throw new Error(error.message);
    }
  },

  /**
   * Perform batch requests
   * @param requests
   */
  async batchFetchRequest<T>(
    requests: BatchGraphQLRequest[]
  ): Promise<T[]> {
    const fetchPromises = requests.map(request =>
      this.fetchRequest<T>(request.query, request.variables ?? {})
    );

    try {
      const results = await Promise.all(fetchPromises);
      return results;
    } catch (error) {
      console.error("Error performing batch GraphQL requests:", error);
      throw error;
    }
  }
}

export default graphQlClient;
