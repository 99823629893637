const graphQlQueries = {
  events: `query events($id: [QueryArgument], $eventStart: [QueryArgument], $limit: Int = 8, $offset: Int = 0, $orderBy: String = "eventStart DESC") {
    entries: eventsEntries(
      id: $id
      eventStart: $eventStart
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      ... on events_default_Entry {
        id
        postDate
        title
        eventStart
        eventEnd
        eventResources {
          ... on eventResources_webcast_BlockType {
            id
            typeHandle
            mediaLink
          }
          ... on eventResources_document_BlockType {
            id
            typeHandle
            file {
              url
            }
          }
        }
        eventCalendarLinks {
          link
          source
        }
      }
    }
    entryCount(section: "events", eventStart: $eventStart, id: $id)
  }`,
  eventIds: `query events($eventStart: [QueryArgument]) {
    entries: eventsEntries(
      eventStart: $eventStart
      orderBy: "eventStart DESC"
    ) {
      ... on events_default_Entry {
        id
        eventStart @formatDateTime(format: "U")
      }
    }
    entryCount(section: "events", eventStart: $eventStart)
  }`,
  filings: `query filings($id: [QueryArgument], $form: [String], $dateFiled: [String], $limit: Int = 8, $offset: Int = 0) {
    entries: filings(form: $form, dateFiled: $dateFiled, limit: $limit, offset: $offset, id: $id) {
      title
      type
      dateFiled @formatDateTime(format: "d M Y")
      form
      ... on Filing {
        id
        htmlFiling: irFilings(
          irFilingType: "html"
          limit: 1
        ) {
          id
          ... on filings_Asset {
            title
            irFilingType
          }
          url
        }
        filings: irFilings(irFilingType: ["not", "html"]) {
          id
          ... on filings_Asset {
            title
            irFilingType
          }
          url
        }
      }
    }
    entryCount: filingCount(dateFiled: $dateFiled, form: $form)
  }
  `,
  filingIds: `query filings($form: [String], $dateFiled: [String]) {
    entries: filings(form: $form, dateFiled: $dateFiled) {
      id
      dateFiled @formatDateTime(format: "U")
    }
    entryCount: filingCount(dateFiled: $dateFiled, form: $form)
  }
  `,
  historic: `query historic($date: [String]) {
    historicPrices(date: $date) {
      open
      close
      adjustedClose
      change
      changePer
      high
      low
      volume
      date @formatDateTime(format: "U")
    }
  }
  `,
  pressReleases: `query pressReleases($id: [QueryArgument], $datePublished: [String], $limit: Int = 8, $offset: Int = 0) {
    entries: pressReleases(id: $id, datePublished: $datePublished, limit: $limit, offset: $offset) {
      releaseId
      title
      datePublished @formatDateTime(format: "d M Y")
      ... on PressRelease {
        id
        bodyText
        teaser
        author
        url
      }
    }
    entryCount: pressReleaseCount(datePublished: $datePublished)
  }
  `,
  pressReleaseIds: `query pressReleases($datePublished: [String]) {
    entries: pressReleases(datePublished: $datePublished, orderBy: "datePublished DESC") {
      datePublished @formatDateTime(format: "U")
      ... on PressRelease {
        id
      }
    }
    entryCount: pressReleaseCount(datePublished: $datePublished)
  }
  `,
  publications: `query Publications($id: [QueryArgument], $postDate: [String], $publicationDrug: [QueryArgument], $offset: Int = 0, $limit: Int = 6) {
    entries: publicationsEntries(id: $id, postDate: $postDate, publicationDrug: $publicationDrug, orderBy: "postDate DESC", offset: $offset, limit: $limit) {
      ... on publications_default_Entry {
        id
        title
        simpleText
        postDate @formatDateTime(format: "d F, Y")
        publicationAuthors
        publicationLink
        publicationDrug {
          title
          id
        }
        publicationDocument {
          url
          title
        }
      }
    }
    entryCount(
        section: "publications"
        postDate: $postDate
        publicationDrug: $publicationDrug
      )
  }`,
  publicationIds: `query Publications($postDate: [String], $publicationDrug: [QueryArgument]) {
    entries: publicationsEntries(postDate: $postDate, publicationDrug: $publicationDrug, orderBy: "postDate DESC") {
      ... on publications_default_Entry {
        id
        postDate @formatDateTime(format: "U")
      }
    }
    entryCount(
        section: "publications"
        postDate: $postDate
        publicationDrug: $publicationDrug
      )
  }`
}

export default graphQlQueries
